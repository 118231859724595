import { COUNTRY, LANGUAGE, TIMESTAMP, TOKEN } from './constants/constants'
import type {
	CacheClassProps,
	CacheKeys,
	CachedAndFetchedKeys,
} from './types/types'

class CacheClass {
	cacheKey: string
	maxDiffTimestamp?: number
	browserSupportsCache: boolean
	isTokenCache: boolean
	isCountryCache: boolean
	isLanguageCache: boolean
	cache: Cache | null
	languageISO?: string
	countryISO?: string
	token?: string

	constructor({
		cacheKey,
		maxDiffTimestamp,
		isTokenCache = false,
		isCountryCache = false,
		isLanguageCache = false,
		languageISO,
		countryISO,
		token,
	}: CacheClassProps) {
		this.cacheKey = cacheKey
		this.maxDiffTimestamp = maxDiffTimestamp
		this.browserSupportsCache = true
		this.isTokenCache = isTokenCache
		this.isCountryCache = isCountryCache
		this.isLanguageCache = isLanguageCache
		this.languageISO = languageISO
		this.countryISO = countryISO
		this.token = token
		this.cache = null
	}

	/**
	 * Delete cache if timestamp is expired
	 * Init cache controller with cacheKey and timestamp
	 */
	init = async () => {
		if (!this.cache) {
			try {
				this.cache = await caches.open(this.cacheKey)
				if ((await this.didCacheExpire()) || (await this.shouldCleanCache())) {
					await this.clean()
				}

				await this.addTimestampToCache()

				if (this.isTokenCache) {
					await this.addTokenToCache()
				}

				if (this.isCountryCache) {
					await this.addCountryToCache()
				}

				if (this.isLanguageCache) {
					await this.addLanguageToCache()
				}
			} catch (error) {
				this.browserSupportsCache = false
				// eslint-disable-next-line no-console
				console.error(
					`Error while initializing cache (Cache KEY :${this.cacheKey}): ${error}`
				)
			}
		}
		return Promise.resolve()
	}

	/**
	 * Parse cache key to response for CacheStorage API
	 */
	static createResponse = (body: unknown): Response =>
		new Response(JSON.stringify(body), {
			headers: {
				'content-type': 'application/json',
			},
		})

	/**
	 * Clean cache
	 */
	clean = async () => {
		if (this.browserSupportsCache) {
			await caches.delete(this.cacheKey)
			this.cache = null
		}
	}

	/**
	 * Add timestamp for this cacheKey
	 */
	addTimestampToCache = async () => {
		const cacheTimestamp = await this.getCacheTimestamp()
		if (!cacheTimestamp) {
			await this.cache?.put(
				`/${TIMESTAMP}`,
				CacheClass.createResponse(Date.now())
			)
		}
	}

	/**
	 * Add Token for this cacheKey
	 */
	addTokenToCache = () =>
		this.cache?.put(`/${TOKEN}`, CacheClass.createResponse(this.token))

	/**
	 * Add Country for this cacheKey
	 */
	addCountryToCache = () =>
		this.cache?.put(`/${COUNTRY}`, CacheClass.createResponse(this.countryISO))

	/**
	 * Add Language for this cacheKey
	 */
	addLanguageToCache = () =>
		this.cache?.put(`/${LANGUAGE}`, CacheClass.createResponse(this.languageISO))

	/**
	 * Check if cache continues or is expired
	 */
	didCacheExpire = async () => {
		const timestamp = await this.getCacheTimestamp()

		if (timestamp && this.maxDiffTimestamp) {
			const diff = Date.now() - timestamp

			return diff >= this.maxDiffTimestamp
		}
		return false
	}

	/**
	 * Check if data from cache is the same as current data for each cache type
	 */
	shouldCleanCacheTypes = async (): Promise<boolean> => {
		const cache = await caches.open(this.cacheKey)

		if (this.isTokenCache) {
			const response = await cache.match(`/${TOKEN}`)
			const token = await response?.clone().json()

			return token && token !== this.token
		}

		if (this.isCountryCache) {
			const response = await cache.match(`/${COUNTRY}`)
			const country = await response?.clone().json()

			return country && country !== this.countryISO
		}

		if (this.isLanguageCache) {
			const response = await cache.match(`/${LANGUAGE}`)
			const language = await response?.clone().json()

			return language && language !== this.languageISO
		}

		return false
	}

	/**
	 * Check from cache if data is the same as actual
	 */
	shouldCleanCache = async () => {
		const needToClean =
			this.browserSupportsCache &&
			(this.isTokenCache || this.isCountryCache || this.isLanguageCache)

		if (needToClean) {
			return this.shouldCleanCacheTypes()
		}

		return false
	}

	/**
	 * Get timestamp from CacheStorage API key
	 */
	getCacheTimestamp = async (): Promise<number | null> => {
		const cache = await caches.open(this.cacheKey)
		const timestamp = await cache.match(`/${TIMESTAMP}`)

		if (timestamp) {
			return timestamp.clone().json()
		}

		return null
	}

	/**
	 * Add key to cache
	 */
	addKeyToCache = async <T>(key: string, value: T) => {
		if (this.browserSupportsCache) {
			if (!this.cache) {
				await this.init()
			}

			await this.cache?.put(`/${key}`, CacheClass.createResponse(value))
		}

		return Promise.resolve()
	}

	/**
	 * Add keys to cache
	 */
	addKeysToCache = async (resources: Record<string, unknown>) => {
		if (this.browserSupportsCache && resources) {
			if (!this.cache) {
				await this.init()
			}

			const arrayResources = Object.entries(resources).map(([key, value]) => ({
				[key]: value,
			}))

			await Promise.all(
				arrayResources.map(async (resource) => {
					const objKey = Object.keys(resource)[0]
					const objValue = Object.values(resource)[0]

					await this.addKeyToCache(objKey, objValue)
				})
			)
		}
		return Promise.resolve()
	}

	/**
	 * Get key from cache
	 */
	getKeyFromCache = async <T>(key: string): Promise<T | undefined> => {
		if (!this.browserSupportsCache) {
			return undefined
		}

		if (!this.cache) {
			await this.init()
		}

		const response = await this.cache?.match(`/${key}`)
		if (response) {
			return response.clone().json()
		}

		return undefined
	}

	/**
	 * Get Keys from CacheStorage API key
	 */
	getKeysFromCache = async (keys: string[]): Promise<CachedAndFetchedKeys> => {
		let noCachedKeys = [...keys]
		const cachedKeys: CacheKeys = {}

		if (this.browserSupportsCache) {
			if (!this.cache) {
				await this.init()
			}

			await Promise.all(
				keys.map(async (key) => {
					const cachedValue = await this.getKeyFromCache(key)

					if (cachedValue) {
						cachedKeys[key] = cachedValue
						noCachedKeys = noCachedKeys.filter((ele) => ele !== key)
					}
				})
			)
		}

		return { cachedKeys, noCachedKeys }
	}

	getAllKeysFromCache = async <T>(): Promise<T | null> => {
		if (this.browserSupportsCache) {
			if (!this.cache) {
				await this.init()
			}

			const keysList = (await this.cache?.keys())?.map((request) => request.url)

			if (keysList) {
				const cachedKeys = {} as T
				for (const key of keysList) {
					const keyWithoutDomain = key.replace(
						`${window.location.origin}/`,
						''
					) as keyof T
					const value = await this.getKeyFromCache(keyWithoutDomain as string)
					cachedKeys[keyWithoutDomain] = value as T[keyof T]
				}

				return cachedKeys
			}
		}
		return null
	}
}

export { CacheClass }
