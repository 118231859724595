'use client'

import { useContext } from 'react'

import { SessionContext } from '../contexts/SessionProvider'
import type { Session } from '../types/session'

/**
 * @throws {Error}
 */
export const useSession = (): Session => {
	const session = useContext(SessionContext)

	if (!session) {
		throw new Error('useSession must be used within a SessionProvider')
	}

	return session
}
