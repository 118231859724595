import { authInfoOrchestratorService } from '../services/authInfoOrchestratorService'
import type { AuthOrchestratorData } from '../types/auth'
import { getSessionCacheStorage } from './cacheStorage/getSessionCacheStorage'
import { setSessionCacheStorage } from './cacheStorage/setSessionCacheStorage'

export const getSession = async (): Promise<AuthOrchestratorData | null> => {
	try {
		const sessionCacheStorage = await getSessionCacheStorage()

		if (sessionCacheStorage) {
			return sessionCacheStorage
		}
	} catch (e) {
		console.error('Error getting session from cache storage', e)
	}

	const fetchSession = await authInfoOrchestratorService()
	if (fetchSession) {
		await setSessionCacheStorage(fetchSession)
		return fetchSession
	}

	return null
}
