// Returns a URL with query params appended (if any)
export function urlConstructor(
	url: string,
	params?: Record<string, string>
): string {
	const urlObject = new URL(url)
	if (params) {
		Object.entries(params).forEach(([key, value]) => {
			urlObject.searchParams.append(key, value)
		})
	}
	return urlObject.href
}
