import type { AuthOrchestratorData } from '../../types/auth'
import { sessionCache } from '../sessionCache'

type SessionCacheStorage = AuthOrchestratorData & { TIMESTAMP: number }

export const getSessionCacheStorage =
	async (): Promise<SessionCacheStorage | null> => {
		const session =
			await sessionCache.getAllKeysFromCache<SessionCacheStorage>()

		if (session?.token) {
			return session
		}
		return null
	}
