import { env } from 'utils/envManager'
import { url } from 'utils/url'

import type { AuthOrchestratorData } from '../types/auth'

const ORQUESTRATOR_INFO_SERVICE_ENDPOINT = url(
	`${env.NEXT_PUBLIC_SITE_BASE_URL}/ws-auth-orchestrator/auth/info`,
	{ channel: env.NEXT_PUBLIC_CHANNEL, app: env.NEXT_PUBLIC_IS_APP }
)

export const authInfoOrchestratorService =
	async (): Promise<AuthOrchestratorData | null> => {
		try {
			const response = await fetch(ORQUESTRATOR_INFO_SERVICE_ENDPOINT, {
				next: { revalidate: 0 },
			})

			if (response.ok) {
				return response.json()
			}
		} catch (e) {
			// Do nothing
		}
		console.error(
			'[Auth Orquestrator Service] error fetching identity auth with cookies'
		)
		return null
	}
