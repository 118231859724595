import {
	LayoutMasterDataIsReadyContext,
	type LayoutMasterDataIsReadyContextValue,
} from 'master-data/contexts/LayoutMasterDataProvider/LayoutMasterDataProvider'
import { useContext } from 'react'

/** @throws {Error} If useMasterData is used without MasterDataProvider */
export const useMasterDataIsReady = (): LayoutMasterDataIsReadyContextValue => {
	const masterDataIsReady = useContext(LayoutMasterDataIsReadyContext)
	if (!masterDataIsReady) {
		throw new Error(
			'useMasterDataIsReady must be used within a LayoutMasterDataIsReadyContextValue'
		)
	}
	return masterDataIsReady
}
