export const COLOR_SEARCH_PARAM = 'c'
export const LOOK_SEARCH_PARAM = 'l'
export const SIZE_SEARCH_PARAM = 's'

export const DEFAULT_LOOK_ID = '00'

export const PRODUCT_STOCK_SERVICE_URL = 'v2/stock/products/'
export const PRODUCT_INFO_SERVICE_URL = 'v3/products/'

export const PRODUCT_STOCK_CLIENT_SERVICE_URL = `ws-online-orchestrator/${PRODUCT_STOCK_SERVICE_URL}`
export const PRODUCT_INFO_CLIENT_SERVICE_URL = `ws-online-orchestrator/${PRODUCT_INFO_SERVICE_URL}`

export const SELECTED_SIZE_ID_KEY = 'selectedSizeId'
export const PRODUCT_FULLSIZE_INFO_AVAILABILITY_KEY = 'available'
