import type { Stored } from '../types'

export function isLocalStorageAvailable(): boolean {
	const windowExists = typeof window !== 'undefined'

	return windowExists && !!window.localStorage
}

export const getFromLocalStorage = <T>(keyName: string): Stored<T> | null => {
	if (!isLocalStorageAvailable()) {
		return null
	}

	try {
		const storedValue: string = window.localStorage.getItem(keyName) || ''

		return JSON.parse(storedValue) as Stored<T>
	} catch (error) {
		return null
	}
}

/**
 * @returns {boolean} "true" if it was able to save it to Local Storage, otherwise it's return is "false"
 */
export const setToLocalStorage = (keyName: string, value: unknown): boolean => {
	if (!isLocalStorageAvailable()) {
		return false
	}

	try {
		const storableValue = JSON.stringify(value)
		window.localStorage.setItem(keyName, storableValue)

		return true
	} catch (error) {
		return false
	}
}

export const removeFromLocalStorage = (keyName: string): boolean => {
	if (!isLocalStorageAvailable()) {
		return false
	}

	try {
		window.localStorage.removeItem(keyName)

		return true
	} catch (error) {
		return false
	}
}
