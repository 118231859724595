import { env } from '../../envManager'

const sliceFirstSlash = (str: string) => {
	if (str.startsWith('/')) {
		return str.slice(1)
	}
	return str
}

export function parseUrlDomain(prefix: string, previousUrl: string): string {
	const envURL = new URL(`${env.NEXT_PUBLIC_SITE_BASE_URL}${prefix}/`)

	const domainURL = new URL(
		previousUrl.startsWith('//') ? `https:${previousUrl}` : previousUrl
	)
	envURL.pathname += sliceFirstSlash(domainURL.pathname)

	return `${envURL.href}${domainURL.search}`
}

export function parse2ApplicationDomain(previousUrl: string): string {
	return parseUrlDomain('', previousUrl)
}

export function parseAsset2ApplicationDomain(previousUrl: string): string {
	return parseUrlDomain('/assets', previousUrl)
}
